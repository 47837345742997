.wrapHeader {
  background-color: #31304D;
  color: white;
  border-bottom: 1px solid #e03131;
  padding: 5px 30px 10px;

  
  @media screen and (max-width: 998.98px) {
    padding: 0 20px 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 10px 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      background-image: linear-gradient(
        45deg,
        #fb6439 0%,
        rgb(250, 82, 82) 100%
      );
      background-clip: text;
      color: transparent;
      // color: #fb633a;
      font-size: 40px;
      transition: all 0.5s;
      // border-top: 2px solid #fff;
      border-bottom: 10px solid #fff;
      // border-right: 2px solid #fff;
      border-left: 2px solid #fff;
      border-radius: 20% 30% 46% 30%;
      cursor: pointer;
      

      @media screen and (max-width: 998.98px) {
        font-size: 35px;
      border-bottom: 8px solid #fff;
      }
      @media screen and (max-width: 768.98px) {
        font-size: 30px;
      }

      &:hover {
        color: red;
        border-color: #fb633a;
        transition: all .5s;

        a {
          color: white;
        }
      }
    }

    .navMovie {
      width: 40%;
      // border-top: 3px solid #fff;
      border-bottom: 3px solid #fff;
      padding: 15px;
      border-radius: 30px;
      font-weight: 900;
      color: #fff;
      box-shadow: 0px 9px 12px -3px #f71;  
      // box-shadow: 0px -9px 12px -3px #f71;
      transition: all 1s;
      background-color: transparent;

      @media screen and (max-width: 998.98px) {
        width: 60%;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
      @media screen and (max-width: 650px) {
        display: none;
      }


      &:hover{
        border-color:yellow ;
        border-top: 3px solid yellow;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          cursor: pointer;
          transition: all 0.5s;
          &:hover {
            a {
              transition: all 0.5s ease;
              color: #fb633a;
            }
          }
        }
      }
    }
    .navMovieScroll {
      position: fixed;
      top: 1%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      // border: 3px solid #fb633a;
      background-color: #000000b3;
      color: #fb633a;
      width: 40%;
      border-radius: 30px;
      padding: 10px 20px;
      font-weight: 900;
      transition: all 1s;

      border-bottom: 3px solid #fff;
      box-shadow: 0px 9px 12px -3px #f71;  


      @media screen and (max-width: 998.98px) {
        width: 60%;
      }

      @media screen and (max-width: 820px) {
        width: 70%;
      }

      @media screen and (max-width: 768.98px) {
        padding: 10px ;
        width: 80%;
      }

      @media screen and (max-width: 520px) {
        font-size: 12px;
      }


      &:hover{
        border-color:yellow ;
        border-top: 3px solid yellow;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          transition: all 0.5s;
          &:hover {
            a {
              transition: all .5s;
              color: #fff;
            }
          }
        }
      }
    }

    .auth{
      // border-bottom: 3px solid #fff;
      border-top: 3px solid #fff;
      // border-left: 3px solid #fff;
      border-right: 3px solid #fff;
      padding: 1em 10px 1em ;
      border-radius: 30px;
      color: #fff;
      transition: all .5s ease;


      @media screen and (max-width: 768.98px) {
        font-size: 14px;
      }
      @media screen and (max-width: 400px) {
        font-size: 12px;
      }

      &:hover{
        border-color:#fb633a ;
        border-bottom: 3px solid #fb633a;
        border-left: 3px solid #fb633a;

        border-top: 0px solid transparent;
        border-right: 0px solid transparent;
      
      }
      span {
        color: #fff;
        padding: 0 5px;
      }

      a {
        transition: all .5s ease;
        &:hover{
          color: #fb633a;
        }
      }

      .navAccount{

        .bodyAccount{
          &:hover{
            background-color: transparent;
          }
        }
        .iconAccount{
          color: #fff;
          margin: 0;
        }
        &:hover {
          background-color: transparent;
          .iconAccount{
            color:#f71;
          }
        }

      }
      .navChildren{
          padding: 0;
          height: 30px;
        &:hover{
          background-color: transparent;
          p{
            color: #f71;
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
